.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.high-desert {
  min-width: 100vw;
  width: 100vw;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

.home-splash {
  z-index: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-splash div {
  width: 60vw;
  height: 45vh;
  padding: 2%;
  background-color: rgba(160, 165, 160, 0.8);
}

.home-splash div h2 {
  margin: 5% 0;
}

.home-page-card {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding-top: 100px;
  margin-bottom: 75px;
  background-color: rgb(80, 90, 108, 0.7) !important;
}

.services-cta-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.services-cta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  height: 85px;
  font-size: 2.6rem;
  border-bottom: 2px solid black;
  margin-bottom: 50px;
}

.services-row,
.area-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 75px 0;
}

.services-row:first-of-type {
  margin-top: 50px;
}

.row-col-first,
.row-col-second,
.area-row-col-first,
.area-row-col-second,
.area-row-col-third {
  margin: 0 25px;
}

.home-page-card-header,
.area-list-card-header {
  background-color: rgba(23, 20, 24, 0.8) !important;
}

.home-list-group-item,
.area-list-group-item {
  padding: 5% !important;
  color: white !important;
  background-color: #212529 !important;
  margin: 0.5% 0;
  border-radius: 15px !important;
  font-size: 1.6rem;
}

.home-page-card-header {
  margin-bottom: 6.75% !important;
  border-radius: 15px !important;
  font-size: 2.4rem;
}

.area-list-card-header {
  margin-bottom: 3.4% !important;
  border-radius: 15px !important;
  font-size: 1.4rem;
}

@media (max-width: 480px) {
  .high-desert {
    max-width: 480px;
    position: relative;
  }

  .home-page-card {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;
    padding-top: 30px;
  }

  .home-splash {
    width: 100vw;
    height: auto;
    margin: 50px 0;
  }

  .home-splash div {
    width: 100%;
    height: auto;
    background-color: rgba(160, 165, 160, 0.8);
  }

  .home-list-group {
    border: none;
  }

  .home-list-group-item {
    margin: 1.5% 0;
  }

  .services-cta {
    width: 100%;
    height: 67.5px;
    font-size: 1.6rem;
    border-bottom: 2px solid black;
    margin-bottom: 50px;
  }

  .cta-cont-text {
    width: 90%;
    font-size: 1.2rem;
  }

  .services-row {
    margin: 0 0 75px 0;
  }

  .services-row:first-of-type {
    margin-top: 65px;
  }

  .row-col-first {
    margin-bottom: 75px;
  }

  .area-row-col-first {
    margin-bottom: 0.5px;
  }

  .area-list-card-header {
    width: 100%;
    border-radius: 15px;
  }

  .home-list-group-item,
  .area-list-group-item {
    font-size: 1rem;
    font-weight: bold;
  }

  .home-page-card-header {
    font-size: 1.8rem;
    text-decoration: underline;
  }
}
