.contact-form-outer-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Oswald", sans-serif;
}

.tool-rack {
  min-width: 100vw;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

.social-media-info {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 1;
}

.social-info-cont {
  width: 50%;
}

.social-info-cont-inner {
  padding: 2%;
  background-color: rgba(100, 100, 100, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(245, 245, 245);
  border-radius: 25px;
}

.social-info-cont h4 {
  font-size: 3.8rem;
  text-decoration: underline;
  margin: 2% 0;
}

.social-info-cont h5 {
  font-size: 2.8rem;
  text-decoration: underline;
  margin-top: 3%;
}

.social-para {
  font-size: 2.2rem;
  width: 65%;
  margin: 3% 0;
}
.social-para:last-of-type {
  margin-bottom: 10%;
}

.social-links {
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2%;
  margin-bottom: 4%;
  background-color: rgba(120, 120, 120);
  height: 224px;
}

.social-media-logo {
  height: 96px;
  width: 96px;
}

.form-image {
  height: 800px;
}

.contact-card {
  width: 55rem;
  padding: 75px 0 50px 0;
  margin: 75px 0;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-card .contact-card-title {
  font-size: 3rem;
  text-decoration: underline;
  margin-bottom: 3%;
}

.contact-card-body {
  width: 75%;
  font-size: 2rem;
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6%;
}

.form-row {
  width: 100%;
}

.form-group {
  text-align: left;
}

.form-label {
  font-size: 1rem;
  font-weight: 1;
}

.form-button-submit {
  margin-top: 10%;
  width: 30%;
  height: 75px;
  font-size: 2rem !important;
}

@media (max-width: 480px) {
  .contact-form-outer-div {
    margin-top: 60px;
  }
  .tool-rack {
    display: none;
  }
  .social-media-info {
    flex-direction: column;
    height: auto;
    margin: 5% 0 0 0;
    z-index: inherit;
  }
  .social-info-cont {
    width: 100%;
  }
  .social-info-cont-inner {
    border-radius: 0;
  }

  .social-info-cont div {
    padding: 10%;
  }
  .social-para {
    margin: 8% 0;
    font-size: 2rem;
  }
  .social-links {
    height: 112px;
    width: 100%;
    margin-top: 4%;
    justify-content: space-between;
    border-radius: 25px;
  }
  .social-media-logo {
    height: 3.2rem;
    width: 3.2rem;
  }
  .form-image-cont {
    /* margin-top: 25%;
    max-width: 500px;
    height: 400px;
    background-position: center;
    background-size: cover;
    overflow: hidden; */
    display: none;
  }
  .form-image {
    width: 100%;
    object-position: 0px -50px;
    overflow: hidden;
  }
  .contact-card {
    width: 100%;
    padding: 12.5% 0 10% 0;
    margin: 0;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-card p {
    font-size: 1.8rem;
    line-height: 2;
  }
  .form-label {
    font-size: 1.8rem;
  }
  .form-message {
    width: 100%;
  }
  .form-button-submit {
    width: 92%;
    margin-top: 20%;
    height: 64px;
    font-size: 1.8rem !important;
  }
}
