.navbar {
  height: 60px + 0.5rem;
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-brand span {
  font-size: 2.5rem;
  margin-left: 10px;
}

.nav-nameplate-logo {
  margin: 0 5px 0 20px;
}

.navbar-divider {
  height: 40px;
  width: 0px;
  opacity: 0.6;
  margin-top: 10px;
  border-right: 1px solid white;
}

.navbar-nav {
  width: 35%;
  margin-top: 10px;
}

.navbar-link {
  color: inherit;
  text-decoration: inherit;
}

.navbar-link:hover,
.navbar-link:visited {
  color: inherit;
}

@media (max-width: 480px) {
  .navbar {
    max-width: 100vw;
  }
  .navbar-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .navbar-brand {
    width: 50%;
    justify-content: center;
  }
  .navbar-brand span {
    font-size: larger;
    padding: 0 0 0 15px;
  }
  .navbar-divider {
    display: none;
  }
  .navbar-nav {
    width: 100%;
    margin: 5% 0 5% 0;
    display: flex;
    flex-direction: column;
  }
  .navbar-nav div,
  .navbar-nav a {
    margin: 20px 0;
    font-size: 2rem;
  }
  .nav-profile-logo {
    height: 50%;
    width: 50%;
    display: none;
  }
  .nav-nameplate-logo {
    /* width: 90%; */
    /* display: none; */
    margin: 15px 0;
  }
}
